.dialogTitle {
  padding: 16px 24px 16px 24px;
}
.dialogContent {
  padding: 16px 36px 8px 36px;
}
.dialogActions {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
  padding: 0;
}

.dialogButton {
  flex: 1;
  max-width: 200px;
}

.noButton {
  background-color: #0000001f;
}

.loaderContainer {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
}

.message {
  margin-left: 10px;
  font-size: 16px;
}

.header {
  padding: 12px 140px;
  display: flex;
  background: #3E4755;
  box-shadow: 0 0 13px 4px rgba(0, 0, 0, 0.3);
}

.logo {
  display: flex;
}

.img {
  margin-right: 10px;
  max-width: 40px;
  border-radius: 8px;
}

.title{
  font-size: 20px;
  line-height: 1;
  color: white;
}

.titleTop{
  font-weight: 500;
}

.titleButtom{
  font-weight: 300;
}

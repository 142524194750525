* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background-color: #f6f7f8;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  color: #141414;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.app {
  display: flex;
  justify-content: center;
  height: calc(100% - 64px);
}

.window {
  margin-top: 64px;
  box-shadow: 0 0 12px 4px rgba(63, 63, 116, 0.1);
  border-radius: 12px;
}

.topLine {
  background-color: #3e4755;
  height: 12px;
  border-radius: 12px 12px 0 0;
  width: 100%;
}

.content {
  border-radius: 0 0 12px 12px;
  background-color: white;
  padding: 44px 56px 56px 56px;
  max-width: 800px;
}

.MuiButton-outlined,
.MuiFormControl-root {
  border-color: rgb(179, 179, 179);
  color: rgb(128, 128, 128);
  font-weight: 500;
  text-transform: none;
}

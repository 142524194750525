.title {
  font-size: 32px;
  font-weight: 700;
}

.description {
  padding-top: 20px;
  font-size: 21px;
  font-weight: 400;
  line-height: 24px;
}

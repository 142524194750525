.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 32px;
  font-weight: 700;
}

.error {
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  color: red;
}

.marketplaces {
  font-size: 18px;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.wildberries {
  color: #9629bc;
}

.ozon {
  color: #005bff;
}

.yandexMarket {
  color: #2b2b2b;
}

.actions {
  max-width: 450px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actionCardHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.icon {
  max-width: 32px;
}

.stepLabel {
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
}

.sendButtonWrapper {
  display: flex;
  margin-top: 16px;
  max-width: 160px;
}

.sendButtonText {
  color: black;
  font-weight: 500;
}

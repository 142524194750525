.container {
    width: 100%;

    svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
        color: var(--iconColor);
    }
}

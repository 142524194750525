.question {
  display: inline-block;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  background-color: #f2ea17;
  color: #000;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  user-select: none;
}

.tooltip {
  max-width: 300px;
  word-wrap: break-word;
  white-space: normal;
}

.two_columns_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 10px;
}

.full_width_container {
  display: grid;
  grid-template-columns: 1fr;
}
